import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, TagCard } from '../components/common'
import { MetaData } from '../components/common/meta'

const Tags = ({ data, location }) => {
    const tags = data.allGhostTag.edges
    return (
      <>
      <MetaData location={location} />
      <Layout isHeader="tag">
          <div className="container mb-5">
              <section className="post-feed tag mt-n11">
                  {
                      tags.map(({ node }, i) => (
                          // The tag below includes the markup for each tag - components/common/TagCard.js
                          <TagCard key={i} tag={node} />
                      ))
                  }
              </section>
          </div>
      </Layout> 
      </>
    )
}

Tags.propTypes = {
    data: PropTypes.shape({
        allGhostTag: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tags

export const pageQuery = graphql`
    {
      allGhostTag(sort: { order: ASC, fields: name }, filter: {visibility: {eq: "public"}}) {
          edges {
              node {
                  slug
                  url
                  name
                  description
                  postCount
              }
          }
      }
      allGhostPost(limit: 1){
        edges {
          node {
             ...GhostPostFields
          }
        }
      }
    }
`
